@use "src/components/utilities/_variables.scss" as *;

/* start it off hidden */
#faq-page {
  display: none;
}

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }

summary {
    font-weight: 600;
    font-size: 18pt;
    margin: 8px;
}

.answer {
    width: 85%;
    padding: 1rem 2rem;
    margin-left: 1rem;;
    align-items: center;
    font-size: 14pt;
    background-color: rgba(35, 12, 15, 0.1);
    border-left: 5px solid $purple;
}

@keyframes sweep {
    0%    {opacity: 0; transform: translateX(-10px)}
    100%  {opacity: 1; transform: translateX(0)}
  }

  @media screen and (max-width: 800px) {
    .answer {
        width: 85%;
        padding: 1rem 2rem;
        margin-left: 0;
        align-items: center;
        font-size: 14pt;
        background-color: rgba(35, 12, 15, 0.1);
        border-left: 5px solid $purple;
    }
  }