@use "src/components/utilities/_variables.scss" as *;

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.gold {
  background-color: $gold;
}

.green {
  background-color: $green;
}

.white {
  background-color: $white;
}

.purple {
  background-color: $purple;
}

.blue {
  background-color: $blue;
}

.pink {
  background-color: $rose;
}

.red {
  background-color: $red;
}

body {
  margin: 0;
  font-family: 'Lora', 'Garamond', 'Times New Roman', Serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  text-align: justify;
}

#skip-to-fast-description {
  background-color: $white;
  border-radius: 0 0 15px 15px;
  box-shadow: 5px;
  position: absolute;
  z-index: 2;
  margin-top: -50px;
  margin-left: 15%;

  transition: margin-top .5s;

  button {
    background-color: transparent;
    border: none;
    border-radius: 0 0 15px 15px;
    box-shadow: none;
    padding: .5rem;
  }
}

#skip-to-fast-description:focus-within {
  margin-top: 0;
}

.main-content {
  /* grid-column: span 1; */

  margin-left: 325px; /* up right down left */
  padding: 45px;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#main-content::-webkit-scrollbar {
  display: none;
}

#text-body {
  margin: auto;
  padding: 50px 65px 50px 65px; /* up right down left */
  max-width: 75%;
  box-shadow: 0 0 3px 0 hsla(0, 0%, 0%, 0.4);
}

#titles {
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h1 {
    font-weight: 600;
    font-size: 40pt;
    margin: 0;
  }

  h2 {
    font-weight: 200;
    font-size: 18pt;
    margin-top: 0;
    font-style: italic;
  }
}

#next-previous-buttons {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
}

#next-previous-buttons img {
  height: 50px;
}

#next-previous-buttons button {
  display: flex;
  margin: 10px;
  background-color: transparent;
  border: none;
  font-family: Lora;
  font-size: 14pt;
}

#next-previous-buttons button:hover {
  cursor: pointer;
}

#previous-arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  align-items: flex-end;
}

#next-arrow {
  align-items: flex-end;
}

/* SMALLER SCREENS */
@media screen and (max-width: 1200px) {
  #text-body {
    padding: 40px;
  }
}

/* MOBILE */
@media screen and (max-width: 800px) {
  #text-body {
    padding: 30px;
  }

  p {
    text-align: left;
  }
  
  .main-content {
    margin-left: 0;
    margin-top: 0;
    padding: 25px;
  }

  #skip-to-fast-description {
    margin-top: -200%;
  }

  #skip-to-fast-description:hover {
    top: -16px;
  }

  #titles {
    h1 {
      font-weight: 600;
      font-size: 35pt;
      margin: 0;
    }

    h2 {
      font-weight: 200;
      font-size: 16pt;
      margin-top: 10px;
      font-style: italic;
    }
  }
}