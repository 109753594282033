#date-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 11rem;

  h1 {
    font-weight: 400;
    font-size: 32pt;
    padding: .5rem;
    text-align: center;
    margin: 0;
  }
  h2 {
    font-weight: 200;
    font-size: 18pt;
    margin-top: 0;
    font-style: italic;
    text-align: center;
  }
}

#next-previous-buttons {
  margin: auto;
  max-width: 300px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

#next-previous-buttons img {
  height: 50px;
}

#fast-icons {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  vertical-align: middle;
}

.fast-icon {
  max-height: 100px;
  padding: 5px;
  flex-grow: 1;
}

.fasted {
  opacity: 25%;
}

#fish {
  max-height: 120px;
}

#fast-description {
  font-size: 14pt;
  margin: 40px;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

@media screen and (max-width: 800px) {
  #date-info {
    height: auto;
  }
}