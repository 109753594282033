@use "src/components/utilities/_variables.scss" as *;

hr {
  border: none; 
  height: 2px;
  background-color: #777; /* Make it visible */
  margin: 20px 0;
  width: 70%;
  align-self: center;
}

#sidebar {
    /* grid-column: span 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    width: 325px;
    position: fixed;
    height: 100%;
    vertical-align: top;
    box-shadow: 0 0 6px 0 hsla(0, 0%, 0%, 0.4);
    text-align: center;
  }

  #site-title {
    margin: 30px auto 0 auto;
    font-weight: 700;
    font-size: 48pt;
    letter-spacing: 0.01em;
    line-height: 1;
    text-align: left;
  }
  
  .hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  #meat-logo {
    height: 55px;
    width: auto;
    vertical-align: bottom;
    margin-left: 10px;
  }
  
  h2 {
    font-size: 18pt;
    font-weight: 200;
  }

  .light {
    background-color: $white;
  }

  .dark {
    background-color: $black;
    color: $white;
  }
  
  a {
    text-decoration: none;
    color: $purple;
  }
  
  #links {
    padding-top: 10px;
  }

  .sidebar-link {
    left: 0;
    text-align: left;
    padding: 16px;
    padding-left: 35px;
    border-left: 8px solid hsla(0, 0%, 0%, 0);
  }
  
  div.sidebar-link {
    font-weight: 400;
    font-size: 22pt;
    text-decoration: none;
    color: #444;
  }
  
  div.sidebar-link:hover {
    background-color: hsla(0, 0%, 0%, 0.05);
    border-left: 8px solid var(--liturgical-color);
    cursor: pointer;
  }
  
  .sidebar-current {
    font-weight: 500;
    color: #111;
    background-color: hsla(0, 0%, 0%, 0.1);
    padding-left: -5px;
    border-left: 8px solid var(--liturgical-color);
  }
  
  .tooltip {
    z-index: 1011;
  }

  @media screen and (max-width: 800px) {
    #sidebar {
      width: 100%;
      height: auto;
      position: relative;
      top: -16px;

      hr {
        display: none;
      }
    }

    #site-title {
      letter-spacing: 0;
      font-size: 28pt;
      text-align: center;
    }

    #meat-logo {
      height: 28px;
      margin-left: 5px;
    }

    h2 {
      font-size: 14pt;
    }

    div.sidebar-link {
        font-weight: 400;
        font-size: 16pt;
        text-decoration: none;
        color: #444;
    }

    .sidebar-link {
        left: 0;
        text-align: left;
        padding: 8px;
        padding-left: 25px;
        border-left: 8px solid hsla(0, 0%, 0%, 0);
      }

    .sidebar-current {
        font-weight: 500;
        color: #111;
        background-color: hsla(0, 0%, 0%, 0.1);
        padding-left: -5px;
        border-left: 8px solid $purple;
    }
  }